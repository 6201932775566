import React, { useEffect, useRef } from "react";
import Rellax, { RellaxInstance } from "rellax";
import MicroModal from "micromodal";
import Swiper from "swiper";
import { Autoplay, EffectCube } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-cube";
import Link from "next/link";

export default function Index() {
  const swiperUIRef = useRef(null);
  const rellaxFirst = useRef(null);
  const rellaxReady = useRef(null);

  useEffect(() => {
    let rellaxInstanceFirst: RellaxInstance | null = null;
    let rellaxInstanceReady: RellaxInstance | null = null;

    if (rellaxFirst.current) {
      rellaxInstanceFirst = new Rellax(rellaxFirst.current, { center: false });
    }

    if (rellaxReady.current) {
      rellaxInstanceReady = new Rellax(rellaxReady.current, { center: true });
    }

    return () => {
      if (rellaxInstanceFirst) rellaxInstanceFirst.destroy();
      if (rellaxInstanceReady) rellaxInstanceReady.destroy();
    };
  }, []);

  useEffect(() => {
    // Initialize MicroModal
    MicroModal.init({
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
    });
  }, []);

  useEffect(() => {
    new Swiper(".dscribeCards", {
      modules: [Autoplay],
      autoplay: { delay: 2500, disableOnInteraction: true },
      loop: true,
      slidesPerView: "auto",
      spaceBetween: 15,
      centeredSlides: true,
    });

    new Swiper(".swiperUI_02", {
      modules: [Autoplay, EffectCube],
      allowTouchMove: true,
      grabCursor: false,
      autoplay: { delay: 2500, disableOnInteraction: true },
      slidersPerView: 1,
      effect: "cube",
      slideShadows: false,
      shadowOffset: false,
      loop: true,
      speed: 800,
    });
  }, []);

  useEffect(() => {
    if (!swiperUIRef.current) return;

    const dscribeExampleSwiper = new Swiper(swiperUIRef.current, {
      modules: [Autoplay],
      allowTouchMove: false,
      spaceBetween: 100,
      speed: 700,
      grabCursor: false,
      autoplay: false,
      slidersPerView: 1,
      on: {
        reachBeginning: function () {
          const typing = document.getElementById("typing");
          typing?.classList.add("typing");
          const send = document.getElementById("send");
          send?.classList.add("send");

          const card = document.getElementById("card");
          card?.classList.remove("card");
        },
        reachEnd: function () {
          const typing = document.getElementById("typing");
          typing?.classList.remove("typing");
          const send = document.getElementById("send");
          send?.classList.remove("send");

          const card = document.getElementById("card");
          card?.classList.add("card");
        },
      },
    });

    // Intersection Observer logic
    const optionsA = {
      root: null,
      rootMargin: "-50% 0px -50% 0px",
      threshold: 0, // ターゲット要素の画面内領域が30%を下回った時にcallbackを発火
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // ビューポートin
          dscribeExampleSwiper.params.autoplay = {
            delay: 2200,
            disableOnInteraction: false,
          };
          dscribeExampleSwiper.autoplay.start();

          dscribeExampleSwiper.slideTo(0);
          const typing = document.getElementById("typing");
          typing?.classList.add("typing");
          const send = document.getElementById("send");
          send?.classList.add("send");
        } else {
          // ビューポートout
          dscribeExampleSwiper.autoplay.stop();

          const typing = document.getElementById("typing");
          typing?.classList.remove("typing");
          const send = document.getElementById("send");
          send?.classList.remove("send");
          const card = document.getElementById("card");
          card?.classList.remove("card");
        }
      });
    }, optionsA);

    // スライダーを監視対象に追加
    observer.observe(swiperUIRef.current);

    return () => {
      if (swiperUIRef.current) observer.unobserve(swiperUIRef.current);
    };
  }, []);

  useEffect(() => {
    const animatedDescription = document.querySelectorAll(
      ".animated_dscription"
    );

    const callbackA = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          wait(0.5).then(() => {
            const thisone = document.getElementById("this");
            if (thisone) {
              thisone.classList.add("animate__pulse");
            }
            // Additional logic here if needed
          });
        } else {
          entry.target.classList.remove("animate__pulse");
        }
      });
    };

    const options = {
      root: null,
      rootMargin: "0px 0px",
      threshold: 0,
    };

    const observerA = new IntersectionObserver(callbackA, options);
    animatedDescription.forEach((tgt) => {
      observerA.observe(tgt);
    });

    // Clean up the observer
    return () => {
      animatedDescription.forEach((tgt) => {
        observerA.unobserve(tgt);
      });
    };
  }, []);

  const wait = (seconds: number) => {
    return new Promise((resolve) => {
      setTimeout(resolve, seconds * 1000);
    });
  };

  return (
    <div id="lp">
      {/* dscribe fullscreen */}
      <article
        className="modal micromodal-slide"
        id="modal-1"
        aria-hidden="true"
      >
        <div className="modal-overlay" tabIndex={-1}>
          <div
            id="dscribe"
            className="modal-container relative"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-1-title"
          >
            <div className="background_01"></div>

            <nav className="menu">
              <ul className="progress_bar">
                <li className="each_bar bg_white"></li>
                <li className="each_bar bg_gray_out"></li>
              </ul>
              <span className="icon_close" data-micromodal-close></span>
            </nav>

            <article className="content relative">
              <h1 className="logo">
                <img
                  src={"/images/lp/logo_white.svg"}
                  alt="Dsscribe.me"
                  title="Dscribe.me"
                />
              </h1>
              <div className="upper">
                <div className="user">
                  <figure className="thumbnail">
                    <img
                      src={"/images/lp/icons/icon_user_r.webp"}
                      alt=""
                      title=""
                    />
                  </figure>
                  <h2 className="name font_white font_bold">REINA</h2>
                  <span className="text font_white">is</span>
                </div>
                <div className="dots">
                  <span className="dot bg_white"></span>
                  <span className="dot bg_white"></span>
                  <span className="dot bg_white"></span>
                </div>
              </div>
              <div className="wrap_dscription">
                <p className="dscription font_white font_bold">
                  It was a few years ago I first met Reina at a photo shooting
                  for some brand. Before then I only knew about her on
                  Instagram, so my impression of her was like a
                  super-beautiful-cool-unapproachable-independent woman.
                  <br />
                  BUT, meet Reina, she’s such a charming and friendly Kansai
                  girl speaking Kansai-Ben(loveliest Japanese dialect :)
                  <br />
                  Of course she’s super beautiful. There’s no place for
                  argument. But look inside, she’s really more than that. Before
                  knowing her in person, I thought she’d have tea only at fancy
                  places like hotel lounges or rooftops, but our Kansai girl
                  stops by a convenience store to get a cup of coffee and chill
                  on a park bench. I bet you’d never imagine that if you don’t
                  know her.
                  <br />
                  Add to that, she prefers Japanese tea and sweets over
                  chocolate and stuff, and she hates cockroaches so much if one
                  is found at home she moves out. That’s our super girl, Reina.
                </p>
              </div>
              <div className="bottom">
                <div className="likes">
                  <span className="icon_heart"></span>
                  <span className="numbers_likes font_white font_bold">
                    118 likes
                  </span>
                </div>
                <div className="from">
                  <span className="text_from font_white">from</span>
                  <figure className="thumbnail">
                    <img
                      src={"/images/lp/icons/icon_user_yu.webp"}
                      alt=""
                      title=""
                    />
                  </figure>
                  <h2 className="from_name font_white font_bold">yuma</h2>
                </div>
              </div>
            </article>
          </div>
        </div>
      </article>

      {/* dscribe fullscreen */}
      <article
        className="modal micromodal-slide"
        id="modal-2"
        aria-hidden="true"
      >
        <div className="modal-overlay" tabIndex={-1}>
          <div
            id="dscribe"
            className="modal-container relative"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-1-title"
          >
            <div className="background_02"></div>

            <nav className="menu">
              <ul className="progress_bar">
                <li className="each_bar bg_white"></li>
                <li className="each_bar bg_gray_out"></li>
              </ul>
              <span className="icon_close" data-micromodal-close></span>
            </nav>

            <article className="content relative">
              <h1 className="logo">
                <img
                  src={"/images/lp/logo_white.svg"}
                  alt="Dsscribe.me"
                  title="Dscribe.me"
                />
              </h1>
              <div className="upper">
                <div className="user">
                  <figure className="thumbnail">
                    <img
                      src={"/images/lp/thumbnail_default.jpg"}
                      alt=""
                      title=""
                    />
                  </figure>
                  <h2 className="name font_white font_bold">Dscribe.me</h2>
                  <span className="text font_white">is</span>
                </div>
                <div className="dots">
                  <span className="dot bg_white"></span>
                  <span className="dot bg_white"></span>
                  <span className="dot bg_white"></span>
                </div>
              </div>
              <div className="wrap_dscription">
                <p className="dscription font_white font_bold">
                  A place where you share the true words by those who really
                  know you, not a random hate-speech by strangers.
                </p>
              </div>
              <div className="bottom">
                <div className="likes">
                  <span className="icon_heart"></span>
                  <span className="numbers_likes font_white font_bold">
                    102 likes
                  </span>
                </div>
                <div className="from">
                  <span className="text_from font_white">from</span>
                  <figure className="thumbnail">
                    <img
                      src={"/images/lp/thumbnail_default.jpg"}
                      alt=""
                      title=""
                    />
                  </figure>
                  <h2 className="from_name font_white font_bold">Dscribe.me</h2>
                </div>
              </div>
            </article>
          </div>
        </div>
      </article>

      {/* dscribe fullscreen */}
      <article
        className="modal micromodal-slide"
        id="modal-3"
        aria-hidden="true"
      >
        <div className="modal-overlay" tabIndex={-1}>
          <div
            id="dscribe"
            className="modal-container relative"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-1-title"
          >
            <div className="background_03"></div>

            <nav className="menu">
              <ul className="progress_bar">
                <li className="each_bar bg_white"></li>
                <li className="each_bar bg_gray_out"></li>
              </ul>
              <span className="icon_close" data-micromodal-close></span>
            </nav>

            <article className="content relative">
              <h1 className="logo">
                <img
                  src={"/images/lp/logo_white.svg"}
                  alt="Dsscribe.me"
                  title="Dscribe.me"
                />
              </h1>
              <div className="upper">
                <div className="user">
                  <figure className="thumbnail">
                    <img
                      src={"/images/lp/thumbnail_default.jpg"}
                      alt=""
                      title=""
                    />
                  </figure>
                  <h2 className="name font_white font_bold">Dscribe.me</h2>
                  <span className="text font_white">is</span>
                </div>
                <div className="dots">
                  <span className="dot bg_white"></span>
                  <span className="dot bg_white"></span>
                  <span className="dot bg_white"></span>
                </div>
              </div>
              <div className="wrap_dscription">
                <p className="dscription font_white font_bold">
                  A service created by great and thoughtful members who have
                  agreed to the purest intentions.
                </p>
              </div>
              <div className="bottom">
                <div className="likes">
                  <span className="icon_heart"></span>
                  <span className="numbers_likes font_white font_bold">
                    106 likes
                  </span>
                </div>
                <div className="from">
                  <span className="text_from font_white">from</span>
                  <figure className="thumbnail">
                    <img
                      src={"/images/lp/thumbnail_default.jpg"}
                      alt=""
                      title=""
                    />
                  </figure>
                  <h2 className="from_name font_white font_bold">Dscribe.me</h2>
                </div>
              </div>
            </article>
          </div>
        </div>
      </article>

      {/* whole content */}
      <div className="pc_background"></div>
      <div className="wrap_whole_content flex items_center">
        <section id="info_pc_view" className="info_pc_view flex items_end">
          <h1 className="logo">
            <img
              src={"/images/lp/logo_white.svg"}
              style={{ width: "100%" }}
              title="Dscribe.me"
              alt="Dscribe.me"
            />
          </h1>
        </section>
      </div>

      <div className="wrap_main_frame">
        <div className="main_frame bg_white">
          {/* header */}
          <header>
            <div className="header__menu">
              <Link href="/">
                <span className="each_language font_bold font_white">ENG</span>
              </Link>
              <span className="divide bg_white"></span>
              <Link href="/jp">
                <span className="each_language font_bold font_white">
                  日本語
                </span>
              </Link>
            </div>
          </header>

          {/* main */}
          <main>
            {/* firstview */}
            <section id="firstview">
              <figure className="content_firstview relative">
                <img
                  src={"/images/lp/content_firstview_en.png"}
                  alt=""
                  title=""
                />
                <Link href="/signup">
                  <span className="signup font_white align_center bg_blue font_bold">
                    Sign Up
                  </span>
                </Link>
              </figure>
              <div
                className="bg_firstview_en bg_firstview rellax_firstview"
                data-rellax-speed="-2"
                ref={rellaxFirst}
              ></div>
            </section>

            {/* concept */}
            <section id="concept" className="relative bg_black">
              {/* cards */}
              <div className="dscribeCards">
                <span className="text_card_tap">
                  <img
                    src={"/images/lp/text_card_tap_en.svg"}
                    style={{ width: "100%" }}
                    alt=""
                    title=""
                  />
                </span>
                <div className="swiper-wrapper">
                  <article
                    className="swiper-slide each_dscription bg_white modal-open"
                    data-micromodal-trigger="modal-2"
                  >
                    <div className="dscriber_info">
                      <div>
                        <figure className="dscriber_thumbnail">
                          <img
                            src={"/images/lp/thumbnail_default.jpg"}
                            alt=""
                            title=""
                          />
                        </figure>
                        <div className="dscriber_detail">
                          <h3 className="dscriber_name  font_bold">
                            Dscribe.me
                          </h3>
                          <span className="dscriber_id  font_gray">
                            @dscribe_me
                          </span>
                        </div>
                      </div>
                      <span className="dscriber_tag  font_bold  bg_gray">
                        Team
                      </span>
                    </div>
                    <p className="dscription paragraph">
                      A place where you share the true words by those who really
                      know you, not a random hate-speech by strangers.
                    </p>
                    <div className="post_info">
                      <div>
                        <figure className="like_icon"></figure>
                        <span className="like_count font_500">102</span>
                      </div>
                      <time className="date_posted font_gray">Read more</time>
                    </div>
                  </article>

                  <article
                    className="swiper-slide each_dscription bg_white modal-open"
                    data-micromodal-trigger="modal-3"
                  >
                    <div className="dscriber_info">
                      <div>
                        <figure className="dscriber_thumbnail">
                          <img
                            src={"/images/lp/thumbnail_default.jpg"}
                            alt=""
                            title=""
                          />
                        </figure>
                        <div className="dscriber_detail">
                          <h3 className="dscriber_name  font_bold">
                            Dscribe.me
                          </h3>
                          <span className="dscriber_id  font_gray">
                            @dscribe_me
                          </span>
                        </div>
                      </div>
                      <span className="dscriber_tag  font_bold  bg_gray">
                        Team
                      </span>
                    </div>
                    <p className="dscription  paragraph">
                      A service created by great and thoughtful members who have
                      agreed to the purest intentions.
                    </p>
                    <div className="post_info">
                      <div>
                        <figure className="like_icon"></figure>
                        <span className="like_count font_500">106</span>
                      </div>
                      <time className="date_posted font_gray">Read more</time>
                    </div>
                  </article>

                  <article
                    className="swiper-slide each_dscription bg_white modal-open"
                    data-micromodal-trigger="modal-1"
                  >
                    <div className="dscriber_info">
                      <div>
                        <figure className="dscriber_thumbnail">
                          <img
                            src={"/images/lp/icons/icon_user_yu.webp"}
                            alt=""
                            title=""
                          />
                        </figure>
                        <div className="dscriber_detail">
                          <h3 className="dscriber_name font_bold">yuma</h3>
                          <span className="dscriber_id font_gray">@yuma</span>
                        </div>
                      </div>
                      <span className="dscriber_tag font_bold bg_gray">
                        Friend
                      </span>
                    </div>
                    <p className="dscription  paragraph">
                      It was a few years ago I first met Reina at a photo
                      shooting for some brand. Before then I only knew about her
                      on Instagram ...
                    </p>
                    <div className="post_info">
                      <div>
                        <figure className="like_icon"></figure>
                        <span className="like_count font_500">118</span>
                      </div>
                      <time className="date_posted font_gray">1 day ago</time>
                    </div>
                  </article>

                  <article
                    className="swiper-slide each_dscription bg_white modal-open"
                    data-micromodal-trigger="modal-2"
                  >
                    <div className="dscriber_info">
                      <div>
                        <figure className="dscriber_thumbnail">
                          <img
                            src={"/images/lp/thumbnail_default.jpg"}
                            alt=""
                            title=""
                          />
                        </figure>
                        <div className="dscriber_detail">
                          <h3 className="dscriber_name  font_bold">
                            Dscribe.me
                          </h3>
                          <span className="dscriber_id  font_gray">
                            @dscribe_me
                          </span>
                        </div>
                      </div>
                      <span className="dscriber_tag  font_bold  bg_gray">
                        Team
                      </span>
                    </div>
                    <p className="dscription paragraph">
                      A place where you share the true words by those who really
                      know you, not a random hate-speech by strangers.
                    </p>
                    <div className="post_info">
                      <div>
                        <figure className="like_icon"></figure>
                        <span className="like_count font_500">102</span>
                      </div>
                      <time className="date_posted font_gray">Read more</time>
                    </div>
                  </article>

                  <article
                    className="swiper-slide each_dscription bg_white modal-open"
                    data-micromodal-trigger="modal-3"
                  >
                    <div className="dscriber_info">
                      <div>
                        <figure className="dscriber_thumbnail">
                          <img
                            src={"/images/lp/thumbnail_default.jpg"}
                            alt=""
                            title=""
                          />
                        </figure>
                        <div className="dscriber_detail">
                          <h3 className="dscriber_name  font_bold">
                            Dscribe.me
                          </h3>
                          <span className="dscriber_id  font_gray">
                            @dscribe_me
                          </span>
                        </div>
                      </div>
                      <span className="dscriber_tag  font_bold  bg_gray">
                        Friend
                      </span>
                    </div>
                    <p className="dscription  paragraph">
                      A service created by great and thoughtful members who have
                      agreed to the purest intentions.
                    </p>
                    <div className="post_info">
                      <div>
                        <figure className="like_icon"></figure>
                        <span className="like_count font_500">106</span>
                      </div>
                      <time className="date_posted font_gray">Read more</time>
                    </div>
                  </article>

                  <article
                    className="swiper-slide each_dscription bg_white modal-open"
                    data-micromodal-trigger="modal-1"
                  >
                    <div className="dscriber_info">
                      <div>
                        <figure className="dscriber_thumbnail">
                          <img
                            src={"/images/lp/icons/icon_user_yu.webp"}
                            alt=""
                            title=""
                          />
                        </figure>
                        <div className="dscriber_detail">
                          <h3 className="dscriber_name font_bold">yuma</h3>
                          <span className="dscriber_id font_gray">@yuma</span>
                        </div>
                      </div>
                      <span className="dscriber_tag font_bold bg_gray">
                        Friend
                      </span>
                    </div>
                    <p className="dscription  paragraph">
                      It was a few years ago I first met Reina at a photo
                      shooting for some brand. Before then I only knew about her
                      on Instagram ...
                    </p>
                    <div className="post_info">
                      <div>
                        <figure className="like_icon"></figure>
                        <span className="like_count font_500">118</span>
                      </div>
                      <time className="date_posted font_gray">1 day ago</time>
                    </div>
                  </article>
                </div>
              </div>

              {/* slogan */}
              <div className="texts_concept">
                <h1 className="slogan">
                  <img src={"/images/lp/catch.svg"} alt="" title="" />
                </h1>
                <div className="wrap_logo">
                  <span className="border bg_white"></span>
                  <h2 className="logo">
                    <img src={"/images/lp/logo_white.svg"} alt="" title="" />
                  </h2>
                </div>
                <p className="introduction paragraph font_white">
                  You are the collection of various versions of yourself that
                  you don’t even recognize but people around you do.
                </p>
              </div>
            </section>

            {/* ask */}
            <section id="ask">
              <h1 className="bubble font_bold align_center">
                &quot;Dscribe me!&quot;
              </h1>
              <p className="then paragraph align_center paragraph font_500">
                They’re gonna tell you and the world how they feel about you.
              </p>

              <div className="swiperUI" ref={swiperUIRef}>
                <div className="swiper-wrapper">
                  {/* ask Dscribe.me */}
                  <article className="swiper-slide screen_ask_dscribeMe shadow">
                    <section className="myPage__requesterIndication">
                      <figure className="logo">
                        <img
                          src={"/images/lp/logo_white.svg"}
                          alt=""
                          title=""
                        />
                      </figure>
                      <div className="user_name">
                        <span className="font_white  font_bold">from</span>
                        <figure className="thumbnail">
                          <img
                            src={"/images/lp/icons/icon_user_r.webp"}
                            alt=""
                            title=""
                          />
                        </figure>
                        <span className="name  font_white  font_bold">
                          REINA
                        </span>
                      </div>
                    </section>
                    <section className="myPage__layer">
                      <div>
                        <div className="dscriber_info">
                          <div>
                            <figure className="dscriber_thumbnail">
                              <img
                                src={"/images/lp/icons/icon_user_yu.webp"}
                                alt=""
                                title=""
                              />
                            </figure>
                            <div className="dscriber_detail">
                              <span className="dscriber_name  font_bold">
                                yuma
                              </span>
                              <span className="dscriber_id  font_gray">
                                @yuma
                              </span>
                            </div>
                          </div>
                          <div className="relationship"></div>
                        </div>
                        <p id="typing" className="typing">
                          It was a few years ago I first met Reina at a photo
                          shooting for some brand. Before then I only knew about
                          her on Instagram, so my impression of her was like a
                          super-beautiful-cool-unapproachable-independent woman.
                          <br />
                          BUT, meet Reina, she’s such a charming and friendly
                          Kansai girl speaking Kansai-Ben(loveliest Japanese
                          dialect :)
                          <br />
                          Of course she’s super beautiful. There’s no place for
                          argument. But look inside, she’s really more than
                          that. Before knowing her in person, I thought she’d
                          have tea only at fancy places like hotel lounges or
                          rooftops, but our Kansai girl stops by a convenience
                          store to get a cup of coffee and chill on a park
                          bench. I bet you’d never imagine that if you don’t
                          know her.
                          <br />
                          Add to that, she prefers Japanese tea and sweets over
                          chocolate and stuff, and she hates cockroaches so much
                          if one is found at home she moves out. That’s our
                          super girl, Reina.
                        </p>
                      </div>
                      <div className="wrap_button">
                        <p className="notes paragraph font_red">
                          Please be careful about personal information as it
                          will be published.
                        </p>
                        <div
                          id="send"
                          className="send font_500 bg_black align_center font_white"
                        >
                          Send
                        </div>
                      </div>
                    </section>
                  </article>

                  {/* arriving Dscription */}
                  <article className="swiper-slide arriving_dscription relative shadow">
                    <nav className="header">
                      <h1 className="header__logo">
                        <a href="/static">
                          <img
                            src={"/images/lp/logo_variant.png"}
                            alt="Dscribe.me"
                            title="Dscribe.me"
                          />
                        </a>
                      </h1>
                      <div className="header__menu">
                        <span className="search">
                          <img
                            src={"/images/lp/icons/icon_search.svg"}
                            alt=""
                            title=""
                          />
                        </span>
                        <span className="notification">
                          <img
                            src={"/images/lp/icons/icon_notification.svg"}
                            alt=""
                            title=""
                          />
                        </span>
                        <span className="my_menu">
                          <img
                            src={"/images/lp/icons/icon_user_r.webp"}
                            alt=""
                            title=""
                          />
                        </span>
                      </div>
                    </nav>
                    <section className="main bg_white">
                      <div className="user">
                        <div className="info">
                          <h1 className="name font_bold">REINA</h1>
                          <p className="description">Model based in Tokyo.</p>
                          <div className="num_users">
                            <ul className="icon_user">
                              <li className="each_icon_user">
                                <img
                                  src={"/images/lp/icons/icon_user_yu.webp"}
                                  alt=""
                                  title=""
                                />
                              </li>
                              <li className="each_icon_user">
                                <img
                                  src={"/images/lp/icons/icon_user_d01.webp"}
                                  alt=""
                                  title=""
                                />
                              </li>
                              <li className="each_icon_user">
                                <img
                                  src={"/images/lp/icons/icon_user_d02.webp"}
                                  alt=""
                                  title=""
                                />
                              </li>
                            </ul>
                            <span className="number font_bold">(10)</span>
                          </div>
                        </div>
                        <figure className="thumbnail">
                          <img
                            src={"/images/lp/icons/icon_user_r.webp"}
                            alt=""
                            title=""
                          />
                        </figure>
                      </div>

                      <div className="link_card_area">
                        <figure className="picture_card shadow_card relative">
                          <img
                            src={"/images/lp/link_card_r.webp"}
                            alt=""
                            title=""
                          />
                        </figure>
                      </div>

                      <div className="my_dscription">
                        <h2 className="headline font_bold">My Dscriptions</h2>
                        <div className="card_area relative">
                          {/* card */}
                          <div id="card">
                            <article className="each_dscription bg_white shadow_card">
                              <div className="dscriber_info">
                                <div>
                                  <figure className="dscriber_thumbnail">
                                    <img
                                      src={"/images/lp/icons/icon_user_yu.webp"}
                                      alt=""
                                      title=""
                                    />
                                  </figure>
                                  <div className="dscriber_detail">
                                    <h3 className="dscriber_name  font_bold">
                                      yuma
                                    </h3>
                                    <span className="dscriber_id  font_gray">
                                      @yuma
                                    </span>
                                  </div>
                                </div>
                                <span className="dscriber_tag  font_bold  bg_gray">
                                  Friend
                                </span>
                              </div>
                              <p className="dscription  paragraph">
                                It was a few years ago I first met Reina at a
                                photo shooting for some brand. Before then I
                                only knew about her on Instagram ...
                              </p>
                              <div className="post_info">
                                <div>
                                  <figure className="like_icon_empty"></figure>
                                  <span className="like_count  font_500">
                                    0
                                  </span>
                                </div>
                                <time className="date_posted  font_gray">
                                  Just now
                                </time>
                              </div>
                            </article>
                          </div>
                        </div>
                      </div>
                    </section>
                  </article>
                </div>
              </div>
            </section>

            {/* back */}
            <section id="back" className="relative bg_white">
              <span className="subtitle font_500 align_center">
                And of course, don’t forget to
              </span>
              <h1 className="title align_center font_bold">
                Dscribe them back!
              </h1>

              <div className="swiperUI_02">
                <div className="swiper-wrapper">
                  <article className="swiper-slide">
                    <div id="dscribe" className="relative">
                      <div className="background_01"></div>
                      <nav className="menu">
                        <ul className="progress_bar">
                          <li className="each_bar bg_white"></li>
                          <li className="each_bar bg_gray_out"></li>
                        </ul>
                        {/* <span className="icon_close" data-micromodal-close></span> */}
                      </nav>
                      <div className="content relative">
                        <h1 className="logo">
                          <img
                            src={"/images/lp/logo_white.svg"}
                            alt="Dsscribe.me"
                            title="Dscribe.me"
                          />
                        </h1>
                        <div className="upper">
                          <div className="user">
                            <figure className="thumbnail">
                              <img
                                src={"/images/lp/icons/icon_user_yu.webp"}
                                alt=""
                                title=""
                              />
                            </figure>
                            <h2 className="name font_white font_bold">yuma</h2>
                            <span className="text font_white">is</span>
                          </div>
                          <div className="dots">
                            <span className="dot bg_white"></span>
                            <span className="dot bg_white"></span>
                            <span className="dot bg_white"></span>
                          </div>
                        </div>
                        <div className="wrap_dscription">
                          <p className="dscription font_white font_bold">
                            Yuma-san, who is always cool. When I first met him a
                            few years ago, he seemed very cool and not much of a
                            talker.
                            <br />
                            But now, my image of Yuma-san is of someone who
                            appears cool but is funny, kind, smart, stylish in
                            every way, and has a wonderful sense of taste. Being
                            with him is so much fun! He has the charm that makes
                            everyone want to talk more with him and learn more
                            about various things.
                            <br />
                            And Phryne who is always with him is really cute
                            too. Seeing the two of them, full of love, makes me
                            feel happy 🤍🤍🤍
                          </p>
                        </div>
                        <div className="bottom">
                          <div className="likes">
                            <span className="icon_heart"></span>
                            <span className="numbers_likes font_white font_bold">
                              88 likes
                            </span>
                          </div>
                          <div className="from">
                            <span className="text_from font_white">from</span>
                            <figure className="thumbnail">
                              <img
                                src={"/images/lp/icons/icon_user_r.webp"}
                                alt=""
                                title=""
                              />
                            </figure>
                            <h2 className="from_name font_white font_bold">
                              REINA
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>

                  <article className="swiper-slide">
                    <div id="dscribe" className="relative">
                      <div className="background_02"></div>
                      <nav className="menu">
                        <ul className="progress_bar">
                          <li className="each_bar bg_gray_out"></li>
                          <li className="each_bar bg_white"></li>
                        </ul>
                        {/* <span className="icon_close" data-micromodal-close></span> */}
                      </nav>
                      <div className="content relative">
                        <h1 className="logo">
                          <img
                            src={"/images/lp/logo_white.svg"}
                            alt="Dsscribe.me"
                            title="Dscribe.me"
                          />
                        </h1>
                        <div className="upper">
                          <div className="user">
                            <figure className="thumbnail">
                              <img
                                src={"/images/lp/icons/icon_user_yu.webp"}
                                alt=""
                                title=""
                              />
                            </figure>
                            <h2 className="name font_white font_bold">Reina</h2>
                            <span className="text font_white">is</span>
                          </div>
                          <div className="dots">
                            <span className="dot bg_white"></span>
                            <span className="dot bg_white"></span>
                            <span className="dot bg_white"></span>
                          </div>
                        </div>
                        <div className="wrap_dscription">
                          <p className="dscription font_white font_bold">
                            It was a few years ago I first met Reina at a photo
                            shooting for some brand. Before then I only knew
                            about her on Instagram, so my impression of her was
                            like a
                            super-beautiful-cool-unapproachable-independent
                            woman.
                            <br />
                            BUT, meet Reina, she’s such a charming and friendly
                            Kansai girl speaking Kansai-Ben(loveliest Japanese
                            dialect :)
                            <br />
                            Of course she’s super beautiful. There’s no place
                            for argument. But look inside, she’s really more
                            than that. Before knowing her in person, I thought
                            she’d have tea only at fancy places like hotel
                            lounges or rooftops, but our Kansai girl stops by a
                            convenience store to get a cup of coffee and chill
                            on a park bench. I bet you’d never imagine that if
                            you don’t know her.
                            <br />
                            Add to that, she prefers Japanese tea and sweets
                            over chocolate and stuff, and she hates cockroaches
                            so much if one is found at home she moves out.
                            That’s our super girl, Reina.
                          </p>
                        </div>
                        <div className="bottom">
                          <div className="likes">
                            <span className="icon_heart"></span>
                            <span className="numbers_likes font_white font_bold">
                              84 likes
                            </span>
                          </div>
                          <div className="from">
                            <span className="text_from font_white">from</span>
                            <figure className="thumbnail">
                              <img
                                src={"/images/lp/icons/icon_user_r.webp"}
                                alt=""
                                title=""
                              />
                            </figure>
                            <h2 className="from_name font_white font_bold">
                              REINA
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </section>

            {/* ready */}
            <section id="ready" className="relative">
              <div
                className="bg_footer rellax_ready"
                data-rellax-speed="-2"
                ref={rellaxReady}
              ></div>
              <h1 className="catch relative align_center font_bold font_white">
                Ready to ask
                <br />
                “Dscribe.me”?
              </h1>
              <Link href="/signup">
                <span className="button font_500 radius_button relative font_white bg_black">
                  Sign Up
                </span>
              </Link>
            </section>
          </main>

          {/* footer */}
          <footer>
            <h1 className="logo">
              <img
                src={"/images/lp/logo_white.svg"}
                alt="Dscribe.me"
                title="Dscribe.me"
              />
            </h1>
            <ul className="content">
              <li className="each_menu">
                <Link href="/terms">
                  <span className="align_center font_white">
                    Terms of Service
                  </span>
                </Link>
              </li>
              <li className="each_menu">
                <Link href="/privacy">
                  <span className="align_center font_white">Privacy</span>
                </Link>
              </li>
              <li className="each_menu">
                <Link href="/cookies">
                  <span className="align_center font_white">Cookie Policy</span>
                </Link>
              </li>
              <li className="each_menu">
                <Link href="/contact">
                  <span className="align_center font_white">Contact</span>
                </Link>
              </li>
            </ul>

            <span className="copyright font_gray align_center">
              2023 Dscribe.me All Rights Reserved.
            </span>
            <div className="icons_sns">
              <a href="//www.instagram.com/dscribe.me" className="icon_instagram"></a>
              <a href="//twitter.com/dscribe_me" className="icon_x"></a>
            </div>
            <span className="note font_gray paragraph">
              *Social Identity Service is a service that helps people not guess
              but ask their contacts who they are to develop a healthy
              self-concept.
            </span>
          </footer>
        </div>
      </div>
    </div>
  );
}
